import { useEffect, useState } from 'react'
import s from './styles.module.scss'
import { Link } from 'react-router-dom'
import { Data8Service } from '../../../services'

interface inputFieldTextProps {
    fieldName: string
    saveValue: (obj: { field: string; value: string }) => {
        success: boolean
        error: string
    }
    submitValue?: any
    fieldType?: string | null
    populateValue?: string
    placeholder?: string
}

export const InputFieldText = (props: inputFieldTextProps) => {
    const { fieldName, saveValue, fieldType, populateValue, placeholder } = props
    const [valueToSave, setValueToSave] = useState('')
    const [errorNotice, setErrorNotice] = useState('')

    useEffect(() => {
        // console.log('VALUE TO POPULATE', populateValue)
        if (typeof populateValue != 'undefined' && populateValue !== '') {
            saveAll(populateValue)
        }
    }, [populateValue])

    useEffect(() => {
        console.log('error message: ', errorNotice)
    }, [errorNotice])

    const onChangeFunc = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.currentTarget.value
        setValueToSave(newValue)
        let toSave = { field: fieldName, value: newValue }
        const isValid = await data8Validation(newValue)
        // console.log(fieldName, ' is valid? ', isValid)

        if (!isValid) toSave.value = ''

        const saveResult = saveValue(toSave)
        // console.log('save result: ', saveResult)

        // !saveResult.success ? setErrorNotice('') : setErrorNotice(saveResult.error)
    }

    const saveAll = async (newValue: string) => {
        // if (!isValid) {
        //     const saveResult = saveValue({ field: fieldName, value: "" });
        //     return;
        // }
    }

    const data8Validation = async (newValue: string) => {
        if (typeof fieldType != 'undefined' && fieldType == 'email' && newValue.length > 0) {
            const data8Valid = await Data8Service('EmailValidation', 'IsValid', {
                email: newValue,
            })
            if (data8Valid.Result === 'Invalid') {
                setErrorNotice('Invalid email')
                return false
            }
        }
        if (typeof fieldType != 'undefined' && fieldType == 'tel' && newValue.length > 0) {
            const data8Valid = await Data8Service('PhoneValidation', 'IsValid', {
                telephoneNumber: newValue,
                defaultCountry: 'GB',
            })

            // if (data8Valid.Result.ValidationResult === 'Invalid') {
            //     console.log('PHONE VALID: ', data8Valid.Result.ValidationResult)
            //     setErrorNotice('Invalid telephone number')
            //     return false
            // }
        }
        setErrorNotice('')

        return true
    }

    return (
        <>
            <input
                className={`${s.inputStyle} ${errorNotice != '' ? s.errorBorder : ''}`}
                // readOnly={typeof populateValue != "undefined" ? true : false}
                type={`${fieldType != null ? fieldType : 'text'}`}
                name={fieldName}
                value={typeof populateValue != 'undefined' ? populateValue : valueToSave}
                onChange={onChangeFunc}
                placeholder={`${placeholder}`}
                // onBlur={() => data8Validation()}
            />
            <span className={`${s.notice} ${errorNotice != '' ? 'error' : ''}`}>{errorNotice}</span>
        </>
    )
}
