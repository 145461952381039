import styles from './styles.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import { SubmitButton, Wrapper } from '../../reusable/'
import { useEffect } from 'react'
import logo from '../../../assets/logo.png'

import { Link, useNavigate } from 'react-router-dom'

function Header() {
    useEffect(() => {}, [])
    const navigate = useNavigate()

    return (
        <header className={`${styles.header}  ${responsiveStyles.header} `}>
            <Wrapper>
                <div className={`${styles.innerHeader} ${responsiveStyles.innerHeader} `}>
                    <div className={`${styles.logo} ${responsiveStyles.logo} `}>
                        {/* <a href="https://www.sentinellegal.co.uk" target="__blank"> */}
                        <Link to={'/'}>
                            <img src={logo} alt="Sentinel Legal Logo" />
                        </Link>
                        {/* </a> */}
                    </div>
                    <SubmitButton
                        btnType="primary"
                        onClick={() => {
                            navigate('/start')
                        }}
                        text="Start Your Check"
                    />
                </div>
            </Wrapper>
            <div className={styles.borderBottomBar}></div>
        </header>
    )
}

export default Header
