export const loanProviders = () => {
    return [
        'Student Loans Company',
        'LoanTube',
        'Loanable',
        'Finance Loans',
        'Honours Trustee Limited (HTL) ',
        'Loans For Students',
        'Prodigy Finance',
        'Lendwise',
        'Guarantor My Loan',
        'Edfinancial Services',
        'Mr Lender',
        'StarLoans',
        'Smart Money People',
        'Pera Loans',
        'Budget Loans (CLB Global Group Ltd)',
        'Resolution Finance Limited',
        'Cashcat',
        'SME Loans',
        'Loanblaster ',
        'PBSL LOANS',
        'Prudent Lenders (Raymond Finance Limited)',
        'loans by mal (Monthly Advance Loans Limited)',
        'Everything Financial',
        'Loanza',
        'Loans Personal ',
        'Express Finance Loans Ltd',
        'Clever Loans (Digitonomy Limited)',
        'Amaze Media (NW)',
        'Loan.co.uk',
        'Loansmart',
        'Loan Radar',
        'Huge Loan Lender',
        'Loans4ops',
        'Multi Month Loans',
        'Tootloans',
        'WSC Finance Ltd',
        'Business Finance Providers',
        'Sponsor Licence & Visas',
    ]
}
