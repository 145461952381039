import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import ReactPixel from 'react-facebook-pixel'
// import ReactGA from 'react-ga4'
// import { mouseflow } from 'react-mouseflow'

// import TiktokPixel from 'tiktok-pixel'
declare global {
    interface Window {
        _mfq: any
    }
}
interface tracklead {
    email: string
    phone: string
}
interface argsInterface {
    fn: (...args: any) => any
}
function gtag(...args: any) {
    window.dataLayer.push(arguments)
    console.log('DATA LAYER: ', window.dataLayer)
}

const pixelId = `${process.env.REACT_APP_META_ID}`
const build_tracking = (script_url: string) => {
    var s = document.getElementsByTagName('script')[0]
    var b = document.createElement('script')
    b.type = 'text/javascript'
    b.async = true
    b.src = script_url
    s.parentNode?.insertBefore(b, s)
}

export const HandleTracking = () => {
    let location = useLocation()

    useEffect(() => {
        trackInitialization(location)
    }, [])

    useEffect(() => {
        trackPageViews(location.pathname)
    }, [location])

    // ReactPixel.track(event, data) // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle('PixelId', event, data) // For tracking default events.
    // ReactPixel.trackCustom(event, data) // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom('PixelId', event, data) // For
}

export const trackInitialization = (location: any) => {
    console.log('TRACKING......', process.env.REACT_APP_LEAD_SOURCE)

    // mouseflow.initialize('9d42e7fd-efb6-4d60-bc54-e02df0469681')
    // build_tracking('//cdn.mouseflow.com/projects/9d42e7fd-efb6-4d60-bc54-e02df0469681.js')
    window._mfq = window._mfq || []

    switch (process.env.REACT_APP_LEAD_SOURCE) {
        case 'meta':
            metaInit()
            gatherUTMS(location)
            break

        // case 'google':
        //     build_tracking('https://www.googletagmanager.com/gtag/js?id=AW-16580693666')
        //     window.dataLayer = window.dataLayer || []
        //     gtag('js', new Date())
        //     gtag('config', 'AW-16580693666')

        //     build_tracking('https://www.googletagmanager.com/gtag/js?id=AW-16583853506')
        //     gtag('js', new Date())
        //     gtag('config', 'AW-16583853506')

        //     break

        // case 'tiktok':
        //     tiktokInit()
        //     break

        default:
            break
    }
}

export const trackPageViews = (pathname: any) => {
    // mouseflow.newPageView(pathname)
    switch (process.env.REACT_APP_LEAD_SOURCE) {
        case 'meta':
            // ReactPixel.pageView()
            break

        // case 'google':
        //     gtag('event', 'pageview', { page: pathname })
        //     break

        // case 'tiktok':
        //     TiktokPixel.pageView()
        //     break

        default:
            break
    }
}

// export const trackLead = ({ email, phone }: tracklead) => {
//     switch (process.env.REACT_APP_LEAD_SOURCE) {
//         case 'meta':
//             ReactPixel.track('Lead', { email, telephone: phone })
//             break

//         // case 'google':
//         //     // gtag({ event: 'Lead' })
//         //     // gtag({ event: 'conversion', send_to: 'AW-16580693666/0eSICI-ls7QZEKKdpeI9' })
//         //     gtag('event', 'conversion', { send_to: 'AW-16580693666/0eSICI-ls7QZEKKdpeI9' })
//         //     gtag('event', 'conversion', { send_to: 'AW-16583853506/g9yICMKEwbcZEMKL5uM9' })
//         //     break

//         // case 'tiktok':
//         //     // tq.track('Lead', {})
//         //     TiktokPixel.track('Contact', {})
//         //     break

//         default:
//             break
//     }
// }

export const trackSubmitApplication = () => {
    switch (process.env.REACT_APP_LEAD_SOURCE) {
        case 'meta':
            // ReactPixel.trackSingle(`${process.env.REACT_APP_META_ID}`, 'SubmitApplication', {})
            break

        // case 'google':
        //     // gtag({ event: 'SubmitApplication' })
        //     // gtag({ event: 'conversion', send_to: 'AW-16580693666/DOx4CN_Is7QZEKKdpeI9' })
        //     gtag('event', 'conversion', { send_to: 'AW-16580693666/DOx4CN_Is7QZEKKdpeI9' })
        //     gtag('event', 'conversion', { send_to: 'AW-16583853506/qTfqCIOPvrcZEMKL5uM9' })
        //     break

        // case 'tiktok':
        //     TiktokPixel.track('SubmitForm', {})
        //     break

        default:
            break
    }
}

export const trackCompleteRegistration = () => {
    switch (process.env.REACT_APP_LEAD_SOURCE) {
        case 'meta':
            // ReactPixel.trackSingle(`${process.env.REACT_APP_META_ID}`, 'CompleteRegistration', {})
            break

        // case 'google':
        //     // gtag({ event: 'CompleteRegistration' })
        //     // gtag({ event: 'conversion', send_to: 'AW-16580693666/Bd_5CIr9srQZEKKdpeI9' })
        //     gtag('event', 'conversion', { send_to: 'AW-16580693666/Bd_5CIr9srQZEKKdpeI9' })
        //     gtag('event', 'conversion', { send_to: 'AW-16583853506/qCqcCLzKxLcZEMKL5uM9' })

        //     break

        // case 'tiktok':
        //     TiktokPixel.track('CompleteRegistration', {})
        //     break

        default:
            break
    }
}

const metaInit = () => {
    const advancedMatching: any = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: false,
        debug: false,
    }
    // console.log('pixel id: ', pixelId)

    // ReactPixel.init(pixelId, advancedMatching, options)
}

// const tiktokInit = () => {
//     const advancedMatching = {
//         email: '',
//         phone_number: '',
//     }
//     const options = {
//         debug: false,
//     }
//     TiktokPixel.init('CPBJJLRC77U5G94V07AG', advancedMatching, options)
// }

const gatherUTMS = (location: any) => {
    /** 
     * 
     * 
        utm_source
        utm_medium
        utm_campaign
        utm_content
        um_id
        utm_term 
     */
    let paramsObj: any = {}
    location.search
        .replace('?', '')
        .split('&')
        .forEach((item: string) => {
            let arr = item.split('=')
            paramsObj[arr[0]] = arr[1]
        })
    const paramString = JSON.stringify(paramsObj)
    if (!localStorage.getItem('utms') || paramString !== '{}') localStorage.setItem('utms', paramString)
}
