import AppRouter from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
function App() {
    AOS.init()
    return (
        <div className="App">
            <AppRouter></AppRouter>
        </div>
    )
}

export default App
