import pageStyles from '../../globals/pages.module.scss'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { SubmitButton, SubmitDiv } from '../reusable'
import { InputFieldText } from '../reusable/inputFieldText'
import { trackCompleteRegistration } from '../../helpers'
import { universities } from '../../data/universities'
import { loanProviders } from '../../data/loanProviders'
import classNames from 'classnames/bind'

function MainForm() {
    const [activeSection, setActiveSection] = useState<number>(1)
    const [dataToSave, setDataToSave] = useState<any>({})
    const [terms, setTerms] = useState(false)
    const formRef = useRef(null)
    const navigate = useNavigate()
    const cx = classNames.bind(styles)

    useEffect(() => {
        console.log('ACTIVE SECTION: ', activeSection)
    }, [activeSection])

    const save = (obj: { field: string; value: string }) => {
        let tempData: any = { ...dataToSave }
        tempData[obj.field] = obj.value
        setDataToSave(tempData)
        return { success: true, error: '' }
    }

    const checkAutoFill = (checkDataToSave: any) => {
        const webform: any = formRef.current
        let formDataToSave: any = {}
        Object.values(webform).forEach((elem: any) => {
            if (['text', 'tel', 'email'].some((t) => t == elem.type)) formDataToSave[elem.name] = elem.value
        })
        checkDataToSave = { ...checkDataToSave, ...formDataToSave }
        return checkDataToSave
    }

    const handleSubmit = async () => {
        console.log('HANDLE SUBMISSION....', dataToSave)
        Loading.standard('This may take a minute.')

        try {
            if (!terms) throw new Error('terms')

            const validate = ['firstname', 'lastname', 'email', 'phone']
            let checkDataToSave = checkAutoFill({ ...dataToSave })

            validate.forEach((check: string) => {
                if (!checkDataToSave[check] || !checkDataToSave[check].length)
                    throw new Error(`Missing required field - ${check}`)
            })

            const response = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_UPLOAD_DATA_URL}`,
                data: checkDataToSave,
                // headers: { "Content-Type": "multipart/form-data" },
            })

            Loading.remove()
            console.log('DATA UPLOAD RESPONSE: ', response.data)
            if (response.data.success) {
                Report.success(
                    'Claim Accepted',
                    'Thank you, we have received your claim.',
                    'Okay',
                    () => (window.location.href = 'https://sentinellegal.co.uk'),
                )
                // trackLead({ email: dataToSave.email, phone: dataToSave.phone })
                // trackCompleteRegistration()
            } else {
                throw new Error(`Duplicate`)
            }
        } catch (error: any) {
            Loading.remove()
            console.log(error.message)
            if (error.message.includes('Missing required field')) {
                Report.failure('Required Fields', error.message, 'okay')
            } else if (error.message == 'terms') {
                Report.failure(
                    'Accept Our Terms',
                    'Please accept the Sentinel Legal terms and conditions and try again.',
                    'okay',
                )
            } else if (error.message == 'Duplicate') {
                Report.failure('Duplicate Claim', 'We have already receive your claim.', 'okay')
            } else {
                console.log('ERROR IS : ', error)
                Report.failure('Error Ocurred', 'Please refresh the page and try again.', 'okay')
            }
        }
    }

    const defaultSubmit = (e: any) => {
        e.preventDefault()
    }

    const handleChange = (e: any) => {
        console.log(e.target.name)
        console.log(e.target.value)

        let tempData: any = { ...dataToSave }
        tempData[e.target.name] = e.target.value
        setDataToSave(tempData)

        if (e.target.name == 'studentLoan' && e.target.value == 'no')
            Report.failure(
                'No Student Loan',
                'You need to have taken a student loan to make a claim.',
                'Okay',
                () => (window.location.href = 'https://sentinellegal.co.uk'),
            )
    }

    const toggleTerms = (e: any) => {
        console.log('TOGGLING TERMS...')
        setTerms(!terms)
    }

    const completeSection = (e: any, classname: string) => {
        let containerNode = e.currentTarget.parentNode.parentNode
        containerNode.classList = `${containerNode.classList} ${classname}`
        setTimeout(() => {
            setActiveSection(activeSection + 1)
        }, 500)
    }
    const arrayYears = (start: number, stop: number, step: number) => {
        return Array.from({ length: stop }, (_, i) => start + i * step)
    }
    return (
        <div className={`${styles.mainForm} ${responsiveStyles.mainForm}`}>
            <div className={`${styles.mainFormContent} ${responsiveStyles.mainFormContent}`}>
                <form onSubmit={(e) => defaultSubmit(e)} ref={formRef}>
                    <div
                        className={cx({
                            formContainer: true,
                            displaySection: activeSection == 1,
                        })}
                    >
                        <div>
                            <h2>Do You Qualify?</h2>
                            <fieldset>
                                <label>Which university did you attend?*</label>
                                <select name={'university'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    {universities().map((item: any) => {
                                        return <option value={item}>{item.toLowerCase()}</option>
                                    })}
                                </select>
                            </fieldset>
                            <fieldset>
                                <label>What year did you graduate?*</label>
                                <select name={'graduationYear'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    {arrayYears(new Date().getFullYear() - 21, 40, -1).map((item) => {
                                        return <option value={item}>{item}</option>
                                    })}
                                </select>
                            </fieldset>
                            <fieldset>
                                <label>Did you take out a studen loan?*</label>
                                <select name={'studentLoan'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    <option value={'yes'}>yes</option>
                                    <option value={'no'}>No</option>
                                </select>
                            </fieldset>
                            <fieldset>
                                <label>Please select your student loan provider?*</label>
                                <select name={'loanProvider'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    {loanProviders().map((item) => {
                                        return <option value={item}>{item}</option>
                                    })}
                                    <option value="other">Other</option>
                                </select>
                            </fieldset>{' '}
                            <br />
                            <SubmitDiv
                                text="NEXT"
                                onClick={(e) => completeSection(e, `${styles.completeSection}`)}
                                btnType="primary"
                            />
                        </div>
                    </div>
                    <div
                        className={cx({
                            formContainer: true,
                            displaySection: activeSection == 2,
                        })}
                    >
                        <div>
                            <h2>Tell us more about your loan</h2>
                            <fieldset>
                                <label>What was the total amount borrowed?*</label>
                                <input name={'amountBorrowed'} onChange={(e) => handleChange(e)} type={'number'} />
                            </fieldset>
                            <fieldset>
                                <label>What is your monthly repayment amount?*</label>
                                <input name={'monthlyRepayment'} onChange={(e) => handleChange(e)} type={'number'} />
                            </fieldset>
                            <fieldset>
                                <label>What is your current outstanding balance on the loan?*</label>
                                <input name={'remaningBalance'} onChange={(e) => handleChange(e)} type={'number'} />
                            </fieldset>
                            <fieldset>
                                <label>Have you made any loan repayments?*</label>
                                <select name={'startedRepayment'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    <option value={'yes'}>yes</option>
                                    <option value={'no'}>No</option>
                                </select>
                            </fieldset>
                            <fieldset>
                                <label>Are your repayment amounts income based or a fixed amount?*</label>
                                <select name={'repaymentType'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    <option value={'fixed'}>Fixed</option>
                                    <option value={'income based'}>Income based</option>
                                </select>
                            </fieldset>
                            <fieldset>
                                <label>Are you aware of any errors in your loan terms or repayments?*</label>
                                <select name={'knownErrors'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    <option value={'yes'}>yes</option>
                                    <option value={'no'}>No</option>
                                </select>
                            </fieldset>
                            <fieldset>
                                <label>Have you experienced financial hardship due to loan repayments?*</label>
                                <select name={'financialHardship'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    <option value={'yes'}>yes</option>
                                    <option value={'no'}>No</option>
                                </select>
                            </fieldset>
                            <fieldset>
                                <label>What is your current employment status?*</label>
                                <select name={'employmentStatus'} onChange={(e) => handleChange(e)}>
                                    <option>Please Select</option>
                                    <option value={'full time'}>Full time employed</option>
                                    <option value={'part time'}>Part time</option>
                                    <option value={'self employed'}>Self employed</option>
                                    <option value={'unemployed'}>Unemployed</option>
                                </select>
                            </fieldset>{' '}
                            <br />
                            <SubmitDiv
                                text="NEXT"
                                onClick={(e) => completeSection(e, `${styles.completeSection}`)}
                                btnType="primary"
                            />
                        </div>
                    </div>
                    <div
                        className={cx({
                            formContainer: true,
                            displaySection: activeSection == 3,
                        })}
                    >
                        <div>
                            <h2>Please enter your details to find out your claim valuation.</h2>
                            <fieldset>
                                <label>Enter your first name*</label>

                                <InputFieldText
                                    fieldName="firstname"
                                    fieldType={'text'}
                                    saveValue={save}
                                    placeholder={'First name*'}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Enter your last name*</label>
                                <InputFieldText
                                    fieldName="lastname"
                                    fieldType={'text'}
                                    saveValue={save}
                                    placeholder={'Last name*'}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Enter your e-mail address*</label>
                                <InputFieldText
                                    fieldName="email"
                                    fieldType={'email'}
                                    saveValue={save}
                                    placeholder={'Email*'}
                                />
                            </fieldset>
                            <fieldset>
                                <label>Enter your telephone number*</label>
                                <InputFieldText
                                    fieldName="phone"
                                    fieldType={'tel'}
                                    saveValue={save}
                                    placeholder={'Phone Number*'}
                                />
                            </fieldset>
                            <div
                                className={cx({
                                    termsClick: true,
                                    clicked: terms,
                                })}
                                onClick={(e) => toggleTerms(e)}
                            >
                                <div></div>
                                <p>
                                    By clicking the button below, you confirm you give your permission for Sentinel
                                    Legal Ltd to contact you by telephone, e-mail or post regarding your claim. You are
                                    also agreeing to Sentinel Legal's terms and conditions.
                                </p>
                            </div>
                            <SubmitDiv text="CHECK" onClick={() => handleSubmit()} btnType="primary" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MainForm
