export const universities = () => {
    return [
        'ANGLIA RUSKIN UNIVERSITY',
        'ARTS UNIVERSITY BOURNEMOUTH',
        'BATH SPA UNIVERSITY',
        'UNIVERSITY COLLEGE BIRMINGHAM',
        'BOURNEMOUTH UNIVERSITY',
        'UNIVERSITY OF BRIGHTON',
        'BRUNEL UNIVERSITY',
        'BUCKINGHAMSHIRE NEW UNIVERSITY',
        'CANTERBURY CHRIST CHURCH UNIVERSITY',
        'NORTHUMBRIA UNIVERSITY NEWCASTLE',
        'THE CITY UNIVERSITY',
        'THE CONSERVATOIRE FOR DANCE AND DRAMA',
        'COVENTRY UNIVERSITY',
        'DE MONTFORT UNIVERSITY',
        'GLASGOW SCHOOL OF ART.',
        "GOLDSMITHS' COLLEGE",
        'IMPERIAL COLLEGE OF SCIENCE, TECHNOLOGY AND MEDICINE',
        'INSTITUTE OF CANCER RESEARCH: ROYAL CANCER HOSPITAL (THE)',
        'UNIVERSITY OF WINCHESTER',
        "KING'S COLLEGE LONDON",
        'KINGSTON UNIVERSITY',
        'LEEDS COLLEGE OF ART',
        'LEEDS METROPOLITAN UNIVERSITY',
        'LEEDS TRINITY UNIVERSITY',
        'THE LIVERPOOL INSTITUTE FOR PERFORMING ARTS',
        'LIVERPOOL HOPE UNIVERSITY',
        'LIVERPOOL JOHN MOORES UNIVERSITY',
        'LONDON METROPOLITAN UNIVERSITY',
        'LONDON SCHOOL OF ECONOMICS & POLITICAL SCIENCE',
        'LONDON SOUTH BANK UNIVERSITY',
        'LOUGHBOROUGH UNIVERSITY',
        'MANCHESTER METROPOLITAN UNIVERSITY',
        'MIDDLESEX UNIVERSITY',
        'NORWICH UNIVERSITY OF THE ARTS',
        'NOTTINGHAM TRENT UNIVERSITY',
        'OXFORD BROOKES UNIVERSITY',
        'QUEEN MARGARET UNIVERSITY, EDINBURGH',
        "QUEEN'S UNIVERSITY OF BELFAST",
        'RAVENSBOURNE',
        'THE ROBERT GORDON UNIVERSITY',
        'ROSE BRUFORD COLLEGE',
        'ROYAL AGRICULTURAL UNIVERSITY',
        'ROYAL HOLLOWAY COLLEGE AND BEDFORD NEW COLLEGE',
        'ROYAL CONSERVATOIRE OF SCOTLAND',
        'SRUC',
        'SHEFFIELD HALLAM UNIVERSITY',
        'SOUTHAMPTON SOLENT UNIVERSITY',
        'STAFFORDSHIRE UNIVERSITY',
        'UNIVERSITY FOR THE CREATIVE ARTS',
        'UNIVERSITY OF WEST LONDON',
        'UNIVERSITY OF BIRMINGHAM',
        'UNIVERSITY OF BOLTON',
        'UNIVERSITY OF LIVERPOOL',
        'UNIVERSITY OF THE HIGHLANDS AND ISLANDS',
        'THE UNIVERSITY OF CHICHESTER',
        'UNIVERSITY OF NORTHAMPTON,THE',
        'UNIVERSITY OF WORCESTER',
        'BIRMINGHAM CITY UNIVERSITY',
        'UNIVERSITY OF CENTRAL LANCASHIRE',
        'UNIVERSITY OF DURHAM',
        'UNIVERSITY OF EAST LONDON',
        'UNIVERSITY OF GLOUCESTERSHIRE',
        'UNIVERSITY OF GREENWICH',
        'UNIVERSITY OF HERTFORDSHIRE',
        'UNIVERSITY OF HUDDERSFIELD',
        'UNIVERSITY OF HULL',
        'UNIVERSITY OF KENT',
        'UNIVERSITY OF LINCOLN',
        'UNIVERSITY OF BEDFORDSHIRE',
        'UNIVERSITY OF NOTTINGHAM',
        'UNIVERSITY OF PORTSMOUTH',
        'UNIVERSITY OF SALFORD',
        'UNIVERSITY OF SHEFFIELD',
        'UNIVERSITY OF SOUTHAMPTON',
        'UNIVERSITY OF SUNDERLAND',
        'UNIVERSITY OF SURREY',
        'TEESSIDE UNIVERSITY',
        'UNIVERSITY OF THE ARTS, LONDON',
        'UNIVERSITY OF WARWICK',
        'UNIVERSITY OF THE WEST OF ENGLAND, BRISTOL',
        'THE UNIVERSITY OF WESTMINSTER',
        'UNIVERSITY OF WOLVERHAMPTON',
        'UNIVERSITY OF YORK',
        'WRITTLE COLLEGE',
        'YORK ST JOHN UNIVERSITY',
        'ASTON UNIVERSITY',
        'BIRKBECK COLLEGE',
        'COURTAULD INSTITUTE OF ART',
        'GLASGOW CALEDONIAN UNIVERSITY',
        'HERIOT-WATT UNIVERSITY',
        'HEYTHROP COLLEGE',
        'INSTITUTE OF EDUCATION, UNIVERSITY OF LONDON',
        'UNIVERSITY OF KEELE',
        'UNIVERSITY OF LANCASTER',
        'LONDON BUSINESS SCHOOL',
        'LONDON SCHOOL OF HYGIENE AND TROPICAL MEDICINE',
        'EDINBURGH NAPIER UNIVERSITY',
        'OPEN UNIVERSITY(THE)',
        'UNIVERSITY OF OXFORD',
        'QUEEN MARY UNIVERSITY OF LONDON',
        'ROEHAMPTON UNIVERSITY',
        'ROYAL COLLEGE OF ART(THE)',
        'ROYAL COLLEGE OF MUSIC',
        'THE ROYAL VETERINARY COLLEGE',
        'SCHOOL OF ORIENTAL AND AFRICAN STUDIES',
        'SCHOOL OF PHARMACY UNIVERSITY OF LONDON(THE)',
        "ST. GEORGE'S, UNIVERSITY OF LONDON",
        'UNIVERSITY OF ABERDEEN',
        'UNIVERSITY COLLEGE LONDON',
        'UNIVERSITY OF BRADFORD',
        'UNIVERSITY OF BRISTOL',
        'THE UNIVERSITY OF BUCKINGHAM',
        'UNIVERSITY OF CAMBRIDGE',
        'UNIVERSITY OF EAST ANGLIA',
        'UNIVERSITY OF EDINBURGH',
        'UNIVERSITY OF ESSEX',
        'UNIVERSITY OF EXETER',
        'UNIVERSITY OF SOUTH WALES/PRIFYSGOL DE CYMRU',
        'UNIVERSITY OF GLASGOW',
        'UNIVERSITY OF LEEDS',
        'UNIVERSITY OF LEICESTER',
        'UNIVERSITY OF LONDON',
        'THE UNIVERSITY OF MANCHESTER',
        'NEWCASTLE UNIVERSITY',
        'UNIVERSITY OF THE WEST OF SCOTLAND',
        'PLYMOUTH UNIVERSITY',
        'UNIVERSITY OF READING',
        'UNIVERSITY OF ST ANDREWS',
        'UNIVERSITY OF STIRLING',
        'UNIVERSITY OF STRATHCLYDE',
        'UNIVERSITY OF SUSSEX',
        'UNIVERSITY OF ULSTER',
        'BISHOP GROSSETESTE UNIVERSITY',
        'CARDIFF UNIVERSITY',
        'THE ROYAL CENTRAL SCHOOL OF SPEECH AND DRAMA',
        'CRANFIELD UNIVERSITY',
        'EDGE HILL UNIVERSITY',
        'GUILDHALL SCHOOL OF MUSIC & DRAMA',
        'NEWMAN UNIVERSITY',
        'GLYNDWR UNIVERSITY',
        'ROYAL ACADEMY OF MUSIC',
        'ROYAL NORTHERN COLLEGE OF MUSIC',
        'ROYAL WELSH COLLEGE OF MUSIC AND DRAMA LIMITED',
        'THE UNIVERSITY OF CUMBRIA',
        "ST MARY'S UNIVERSITY, TWICKENHAM",
        'SWANSEA METROPOLITAN UNIVERSITY',
        'UNIVERSITY OF CHESTER',
        'UNIVERSITY OF ABERTAY DUNDEE',
        'UNIVERSITY OF BATH (THE)',
        'UNIVERSITY OF DERBY',
        'UNIVERSITY OF DUNDEE',
        'CARDIFF METROPOLITAN UNIVERSITY',
        'SWANSEA UNIVERSITY',
        'PRIFYSGOL ABERYSTWYTH',
        'BANGOR UNIVERSITY',
        'UNIVERSITY OF WALES: TRINITY SAINT DAVID',
        'STRANMILLIS UNIVERSITY COLLEGE',
        'TRINITY LABAN CONSERVATOIRE OF MUSIC AND DANCE LTD',
        "ST MARY'S UNIVERSITY COLLEGE",
        'UNIVERSITY OF WALES PRIFYSGOL CYMRU',
        'FALMOUTH UNIVERSITY',
        'NORTHERN SCHOOL OF CONTEMPORARY DANCE',
        'UNIVERSITY CAMPUS SUFFOLK LTD',
        'LEEDS COLLEGE OF MUSIC',
        'UNIVERSITY OF ST MARK & ST JOHN',
        'HARPER ADAMS UNIVERSITY',
    ]
}
